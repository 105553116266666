export function scrollAnimatedTo(targetElement: HTMLElement) {
    const rect = targetElement.getBoundingClientRect();
    const nav = document.querySelector<HTMLElement>('header .nav');
    const navStyle = window.getComputedStyle(nav);
    const offset = nav.getBoundingClientRect().height;
    if (navStyle.position !== 'fixed') {
        /**
         * Note: Unclear, why the condition below was required.
         * When looking at http://localhost:8000/de-ch/minimum-risk/#bitcoin
         * it seems to be not needed.
        */

        // const pageTitleBlock = document.querySelector<HTMLElement>("header .page-title-block");
        // if (pageTitleBlock) {
        //     offset = parseFloat(window.getComputedStyle(pageTitleBlock).marginTop);
        // }
    }
    const scrollTop = document.documentElement.scrollTop;
    const newScrollTop = rect.top + scrollTop - offset;

    window.scrollTo({
        top: newScrollTop,
        behavior: 'smooth'
    });

    if (targetElement.classList.contains('open-overlay-button')) {
        targetElement.dispatchEvent(new Event('click'));
    }
}