(function () {
  if (!window['chatbase'] || window['chatbase']('getState') !== 'initialized') {
    // eslint-disable-next-line no-shadow-restricted-names
    window['chatbase'] = (...args) => {
      if (!window['chatbase'].q) {
        window['chatbase'].q = [];
      }
      window['chatbase'].q.push(args);
    };
    window['chatbase'] = new Proxy(window['chatbase'], {
      get(target, prop) {
        if (prop === 'q') {
          return target.q;
        }
        return (...args) => target(prop, ...args);
      },
    });
  }
  if (window['chatbaseInitialMessage']) {
    window['chatbase'].setInitialMessages(window['chatbaseInitialMessage']);
  }
  const onLoad = function () {
    const script = document.createElement('script');
    script.src = 'https://www.chatbase.co/embed.min.js';
    script.id = 'acg9arpIfJeG4RZ5lTHLR';
    script.domain = 'www.chatbase.co';
    document.body.appendChild(script);
  };
  if (document.readyState === 'complete') {
    onLoad();
  } else {
    window.addEventListener('load', onLoad);
  }
})();
