import { Component } from '../../../libs/components';


class TableSwitchButtonsComponent extends Component {
  /**
   * Make sure to use the following quote format for the HTML-config:
   * data-config='{"left":0,"right":1,"minimum-risk-btc":2}'
   */
  tableEl: HTMLTableElement;

  onInit(): void {
    this.host.querySelectorAll('.pillow-scroll-list__item__link').forEach((item: HTMLElement) => {
      item.addEventListener('click', event => {
        event.preventDefault();
        this.clickButton((item.getAttribute('data-cell')));
      });
    });

    this.tableEl = document.getElementById('anlagemodelle-table') as HTMLTableElement;
  }

  _getActiveCellIdx(column: string) {
    const tableConfig = JSON.parse(this.tableEl.getAttribute('data-config'));
    return tableConfig[column];
  }

  clickButton(column: string) {
    this.host.querySelectorAll('.pillow-scroll-list__item__link').forEach((item: HTMLElement) => {
      item.classList.remove('pillow-scroll-list__item__link--active');
      if (item.dataset.cell === column) {
        item.classList.add('pillow-scroll-list__item__link--active');
      }
    });

    this.tableEl.setAttribute('data-active-cell', this._getActiveCellIdx(column));
  }
}
TableSwitchButtonsComponent.declare('.pillow-scroll-list--anlagemodelle');